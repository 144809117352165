.dialog-product {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    .btns-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
    }

    .group-block {
        width: 100%;
    }
}
.footer-images{
    display: flex;
    justify-content: flex-end;
    img{
        height: 35px;
    }
}
.login-button-group{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.align-self-end{
    align-self: flex-end;
}
.underlined{
    text-decoration: underline;
}
.last-item-right {

    // width: calc(100% - 12rem) !important;
    div {
        width: 100%;
    }
}

.marg-left-auto {
    margin-left: auto !important;
}

.footer-logo {
    width: 100px;
}

.spooortsLogo {
    content: url('../../../icons/spooorts_logo.svg');
}

.spoortsLogoWhite {
    content: url('../../../icons/spooorts_white.svg');
}
.seLogoWhite{
    content: url('../../../icons//se2in_logo_fullwhite.png');
}

.logoPdf {
    content: url('../../../icons/pdf.png');
}

.logoXml {
    content: url('../../../icons/xml.png');
}

.logoCsv {
    content: url('../../../icons/csv.png');
}

.default-icon {
    margin-left: 10px;
    background: transparent;
    color: black;
    border-radius: 250px;
    border-color: black;
    height: 30px !important;
    width: 30px !important;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flex-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

.flex-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.p-flex-group {
    display: flex;

    .group-block {
        display: flex;
        flex-direction: column;
        gap: 5px;

        input {
            width: 95%;
        }
    }

    .g-10 {
        gap: 10px;
    }

    .h-100-fixed {
        height: 100px;
    }
}

.align-end {
    align-items: flex-end;
}
.align-center{
    align-items: center;
}

.flex {
    display: flex;
}

.jcsb {
    justify-content: space-between;
}

.jcc {
    justify-content: center;
}
.jcfe{
    justify-content: flex-end;
}
.chart-bar{
    .p-chart{
        canvas{
            max-height: 400px !important;
        }
    }
}

.text-center {
    text-align: center;
}

.align-center {
    align-items: center;
}



.container-block {
    background-color: #FAFAFA;
    margin-top: 20px;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;

    .block-row {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .group-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 5px;

            input {
                width: 95%;
            }

            .p-dropdown {
                background: transparent !important;
            }
        }


    }
}

.container-splitter {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .left-block {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .container-resp {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    @media (max-width: 800px) {
        .block {
            flex-basis: 100%;
            /* Each block occupies full width of the container */
        }
    }

    .block {
        padding: 10px;
        box-sizing: border-box;
    }

    .block-1 {
        width: 8%;
    }

    .block-2 {
        width: 17%;
    }

    .block-3 {
        width: 25%;
    }

    .block-4 {
        width: 33%;
    }

    .block-5 {
        width: 41%;
    }

    .block-6 {
        width: 50%;
    }



    .right-block {
        width: 35%;
        display: flex;
        flex-direction: column;
        background-color: white;
    }
}

.responsive {
    flex-wrap: wrap;

    @media (max-width: 800px) {
        div {
            flex-basis: 100%;
            width: 100%;
            /* Each block occupies full width of the container */
        }
    }
}

.btn-outline {
    justify-content: center;
    background-color: transparent;
}

.colored {
    color: rgb(41, 79, 148);
}

.border-custom {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.sized {
    font-size: 12px;
}

.no-gap {
    gap: 0px !important;
}

.card-custom {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.dialog-container {
    max-width: 800px;

}

.flex-column {
    display: flex;
    flex-direction: column;
}

.inner-block {
    padding: 20px 10px;
}

.no-mb {
    margin-bottom: 0 !important;
}

.uppercase {
    text-transform: uppercase;
}

.m-tr-20 {
    margin: 10px 10px 0px 0px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.ml-10 {
    margin-left: 10px;
}
.ml-20{
    margin-left: 20px;
}
.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-15-p {
    margin-right: 15%;
}


.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-33 {
    width: 33%;
}
.w-35{
    width: 35%;
}
.w-40 {
    width: 40%;
}

.w-49 {
    width: 49%;
}
.w-50{
    width: 50%;
}
.w-55 {
    width: 55%;
}

.w-65 {
    width: 65%;
}
.w-70 {
    width: 70%;
}
.w-75 {
    width: 75%;
}
.w-80{
    width: 80%;
}
.w-85 {
    width: 85%;
}

.w-95{
    width: 95%;
}

.w-100 {
    width: 100% !important;
}

.g-5 {
    gap: 5px
}
.g-10 {
    gap: 10px;
}
.g-15{
    gap: 15px;
}
.g-20{
    gap: 20px;
}