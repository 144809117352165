$primaryColor: #4285f4;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;
$primaryBackground:  #000;
$dark: #373737;
$bluegray: #607D8B;
$gray: #757575;
$lightgray: #eeeeee;
$white: #fff;
$light: #fafafa;
$primaryDark: #294f94;

// $highlightBg: #E3F2FD;
$highlightBg:#373737;
// $highlightTextColor: #495057;
$highlightTextColor: #E3F2FD;

@import '../../sass/theme/theme_light';