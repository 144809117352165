.no-route{
    position: absolute;
    top:30px;
}
Polyline:hover{
    cursor: pointer;
}
.dialog-container {
    display: flex;
    background-color: white;
    .content-wrapper{
        padding-top: 0px !important;
    }

    i {
        font-size: 20px;
        border: 1px solid black;
        padding: 5px;
        color: white;
        background: black;
        border-radius: 50%;
    }

   
    .km-markering, .autorouting{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .logos{
        display: flex;
        gap: 10px;
    }
    .btn-group{
        display: flex;
        gap: 5px;
    }
    .imprint{
        display: flex;
    }
}

.leaflet-edit-route-container {
    height: 100%;

    button.min-width {
        min-width: 100px !important;
    }

    display: flex;
    background-color: white;

    i {
        font-size: 20px;
        border: 1px solid black;
        padding: 5px;
        color: white;
        background: black;
        border-radius: 50%;
    }

    .menu-toggle-button {
        margin: auto;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 100%;
        height: 30px;
        width: 30px;
        /* display: inline-block; */
        z-index: 9999;
        background: white;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-clip: padding-box;
        color: black !important;

        &:active {
            background: white !important;
            color: black !important;
            border-color: black !important;
        }
    }

    .menu-toggle-button {
        display: none;
    }

    .map-block {
        flex-grow: 1 !important;
        height: inherit;

        .leaflet-container {
            height: 100%;
        }
    }

    .color-overwrite-green {
        div {
            background: #027100 !important;
            border-color: #027100 !important;
        }
    }

    @media(max-width:1440px) {
        .menu {
            width: 30% !important;
        }
    }


    @media(max-width:720px) {
        .menu {
            display: none !important;
        }

        .map-block {
            width: 100% !important;
        }

        .menu-toggle-button {
            display: inline-block;
        }
    }


    .menu {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 20px;
        max-width: 320px;
        min-width: 280px;
        height: 100vh;
        overflow: scroll;

        .header {
            margin-top: 20px;
        }

        .line-block {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .map-block {
        flex-grow: 1;
    }


}

.dialog-block {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.error-block {
    display: block;
    position: relative;
    padding: 15px 20px;
    width: 100%;
    height: max-content;
    background-color: #f2dede;
    color: #b94a48;

    span {
        position: absolute;
        right: 19px;
        font-weight: bold;
        cursor: pointer;
    }

    strong {
        color: #b94a48;
    }
}

.default-msg-block {
    display: block;
    position: relative;
    padding: 15px 20px;
    width: 100%;
    height: max-content;
    background-color: #d9edf7;
    color: #3a87ad;

    span {
        position: absolute;
        right: 19px;
        font-weight: bold;
        cursor: pointer;
    }

    strong {
        color: #3a87ad;
    }
}

.image-container {
    text-align: center;
    border: 1px solid grey;
    border-radius: 50%;
    width: 170px;
    height: 170px;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    margin: 0 auto;
}

.leaflet-container {
    height: 100vh;
    width: 100%;
}

.custom-file-input {
    width: 140px !important;
}

.custom-file-input-safari {
    width: 40px !important;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.btn-import {
    border: 1px solid #999;
    padding: 5px 8px;
    color: black !important;
}

.header-block {
    height: 60px;
    background-color: white;
    border-bottom: 1px solid grey;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .button-menu {
        position: absolute;
        margin-left: 15px;
    }
}

.image-logo {
    height: 40px;
    margin: auto;
}

.main-wrapper {
    display: flex;
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-start;

    .left-block {
        height: calc(100vh + 60px);
        width: 270px;
        background: #343a40;
        border-right: 0 none;
        background-image: linear-gradient(180deg, #343a40 10%, #2e3439 100%);

        .wrapper {
            padding: 20px;

            .logo {
                height: 40px;
            }

            hr {
                border-top: slim !important;
            }

            li {
                margin: 15px;
                color: rgba(255, 255, 255, 0.8);
                font-size: 1rem;
            }

            .active {
                font-weight: bold;
            }
        }
    }

    .right-block {
        width: 100%;
    }

    .main-block {
        width: 100%;
        height: 100%;
    }
}
.login-bg{
    background-image: url('../../assets/profile/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.close-btn{
    height: 25px;
    margin-top: 5px;
    font-size: 20px;
    width: 100%;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
        cursor: pointer;
    }
}
.mobile-err-block {
    display: none !important;

    @media(max-width:720px) {
        display: block !important;
    }
}

.markers-group {
    height: 65px;
    align-items: center;
    justify-content: center;
    display: flex;
    justify-self: center;
    flex-wrap: wrap;
    gap: 10px;

    div {
        border: 1px solid black;
        cursor: pointer;
        border-radius: 5px;
        padding: 7px;
        text-align: center;
        width: 35px;
        height: 35px;

        img {
            height: 15px;
        }
    }

    .active {
        border: 3px solid black !important;
    }
}

.custom-file-input {
    display: inline-block;
    background-color: white;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: black;

    &:hover,
    &:active,
    &:enabled {
        background-color: white !important;
        color: black !important;
        border-color: black !important;
        background: white !important;
        box-shadow: 0 0 0 0.2rem #ffffff;
    }
}

.fs-12 {
    font-size: 12px;
}

.cursor {
    cursor: pointer;
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.p-galleria .p-galleria-thumbnail-container{
    background-color: transparent !important;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next{
    background-color: black !important;
}
@media screen and (max-width: 650px) {
    .image-gallery{
        height: 300px !important;
    }
}
@media screen and (max-width: 450px) {
    .image-gallery {
        height: 200px !important;
    }
}
@media screen and (max-width: 400px) {
    .image-gallery {
        height: 150px !important;
    }
}
@media screen and (max-width: 350px) {
    .image-gallery {
        margin-top: 10px;
        height: 100px !important;
    }
}