@import '../../../public/assets/theme/blue/theme-dark.scss';
@media screen and (max-width:768px) {
    .p-datatable-emptymessage{
        display: none !important;
    }
    .hidden-tbody{
        display: none;
    }
    .p-fluid, .field{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .p-datatable-table{
        display: flex;
        flex-direction: row;
        .p-datatable-thead{
            display: none !important;
        }
        .p-datatable-thead, .p-datatable-tbody{
            tr{
                display: flex;
                flex-direction: column;
                th, td{
                    border: 0 !important;
                }
            }
        }
        .p-datatable-tbody{
            display: flex;
            flex-direction: row;
            width: 100%;
            flex-wrap: wrap;
            .p-datatable-thead{
                display: table-header-group !important;
                width: 35%;
                tr, tr th{
                    width: 100% !important;
                    min-width: 90% !important;
                    max-width: 100% !important;
                    height: 100%;
                }
            }
            tr{
                width: 30%;
                border-right: 1px solid #e9ecef !important;
                border-bottom: 1px solid #e9ecef;
                border-width: 0 0 1px 0;
                align-items: center;
                td{
                    // min-height: 42px;
                    min-height: 55px;
                    text-align: center;
                }
            }
        }
    }
    .p-flex-group, .block-row, .flex-row, .dialog-product{
        display: flex;
        flex-direction: column !important;
        .group-block, .w-33, .flex-column, .w-40, .w-20{
            width: 100% !important;
            margin: 0px 0px 10px 0px;
            margin-right: 0 !important;
            input{
                width: 100%;
            }
        }
        .flex{
            justify-content: center !important;
        }
        .social{
            flex-direction: column;
            width: 100%;
            margin-top: 10px;
            margin-right: 0;
            .flex-column{
                align-items: flex-start;
            }
            .flex, .flex-column, .ml-10{
                margin-left: 0;
            }
        }
    }
    // .p-flex-group .p-button{
    //     width: 100%;
    // }
    .more-buttons{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        button{
            width: 48%;
            margin: 0;
            max-width: 48%;
        }
    }
    .checkbox{
        flex-direction: row !important;
        width: 100%;
        align-items: center;
        .group-block:nth-child(1){
            width: 15% !important;
        }
        .group-block:nth-child(2){
            width: 85%;
        }

    }
    .p-dropdown-label {
        min-width: 120px;
    }
    .buttons-row-container{
        display: flex !important;
        flex-direction: row !important;
        align-self: center;
        // justify-content: space-between;
    }
    .mobile-row{
        display: flex !important;
        flex-direction: row !important;
        align-self: center;
        justify-content: space-between;
        width: 25%;
    }
    .payment-row{
        .group-block{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
    .group-block{
        display: flex;
        justify-content: center;
    }
    .import-button{
        align-items: center;
    }
    .p-calendar{
        width: 100%;
    }
    .template-flex{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        .group-block{
            width: 100%;
            align-items: center;
            margin-bottom: 40px !important;
            border-bottom: 1px solid #EEE;
            padding-bottom: 30px;
            .info-label{
                font-size: 14px;
            }
        }
    }
    .website-block{
        margin-top: 20px;
    }
    .container-block{
        .block-row{
            .group-block input{
                width: 100%;
            }
        }
    }
    .empty-warehouse{
        .p-datatable-wrapper .p-datatable-table .p-datatable-tbody .p-datatable-emptymessage{
            border: 0!important;
            border-right: 0 !important;
        }
    }
    .desktop-footer{
        display: none;
    }
    .mobile-footer{
        display: flex;
    }
    .desktop-footer-icons{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        vertical-align: middle !important;
    }
    .p-dialog{
        min-width: 90% !important;
        max-width: 90% !important;
    }
}
@media screen and (max-width:500px) {
    .p-datatable-table {
        .p-datatable-tbody{
            .p-datatable-thead{
                width: 43%;
                tr, tr th{
                    width: 100% !important;
                    min-width: 90% !important;
                    max-width: 100% !important;
                    font-size: 12px;
                    height: 100%;
                }
            }
            tr{
                width: 56% !important;
                min-width: 56%;
                max-width: 56%;
            }
        }

    }
    .fc-daygrid-more-link{
        color: #197676;
        font-size: 10px;
        top: -5px;
    }
    .fc.fc-theme-standard .fc-view-harness td{
        height: 50px;
    }
    .images-container{
        display: flex;
        flex-direction: column !important;
        flex-wrap: wrap;
        .card{
            max-width: 100%!important;
            width: 100% !important;
            margin-bottom:20px !important;
            margin-right: 0 !important;
            img{
                max-width: 90%;
            }
        }
    }
    .p-tabview .p-tabview-nav{
        width: 100vh;
    }
}
@media screen and (min-width:500px) {
    .fc-daygrid-day-events{
        margin-top: -3px !important;
    }
    
}
@media screen and (max-width: 678px){
    .fc .fc-toolbar{
        flex-direction: column;
        justify-content: center;
        .fc-toolbar-chunk{
            margin-bottom: 10px;
        }
    }
}
@media screen and (min-width:769px) {
    .field{
        display: flex;
        flex-direction: column;
    }
    .buttons-row-container{
        display: flex !important;
        flex-direction: row !important;
    }
    .mobile-row{
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        width: 15%;
    }
    .p-datatable{
        width: 100%;
    }

    .p-tabview .p-tabview-nav{
        padding-left: 0px !important;
        width: 100% !important;
    }
    .template-flex{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        .group-block{
            width: 32%;
            align-items: center;
            .info-label{
                font-size: 14px;
            }
        }
    }
    .p-datatable-table{
        .p-datatable-thead{
            display: table-header-group !important;
        }
        .p-datatable-tbody{
            display: table-row-group !important;
            .p-datatable-thead{
                display: none !important;
            }
            tr{
                width: 100%;
                min-width: 90%;
                max-width: 100%;
            }
        }
    }
    .desktop-footer{
        display: flex;
        padding-top: 15px;
        padding-left: 45px;
        .desktop-footer-icons{
            padding: 0 !important;
        }
        .desktop-footer-fb{
            padding-right:  0;
        }
    }
    .mobile-footer{
        display: none;
    }
    .desktop-footer-icons{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .desktop-footer-fb{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 0 !important;
    }
    .layout-content {
        padding-right: 1.3rem !important;
        padding-left: 1.3rem !important;
    }
    .p-dialog{
        min-width: 65% !important;
        max-width: 80% !important;
    }
    .p-dialog-top{
        align-items: center !important;
    } 
}
// @media screen and (min-width:1024px) {
//     .responsive-dashboard{
//         display: flex;
//         // flex-direction: row-reverse !important;
//         // flex-wrap: nowrap;
//         // .card:nth-child(1){
//         //     max-width: 60%;
//         // }
//         .card:nth-child(2){
//             margin-bottom: 0;
//             // max-width: 39%;
//         }
//     }
// }
.p-flex-group{
    justify-content: space-between;
    // align-items: flex-end;
}
hr{
    margin-top: 10px;
    margin-bottom: 10px;
}
.buttons{
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    button{
        width: 49% !important;
    }
}
.start{
    align-items: start;
    align-self: start;
    justify-content: flex-start !important;
}
.button{
    justify-content: center;
}
.smallimg{
    padding: 15px;
    .p-image{
        img{
            border-radius: 50%;
        }
    }
}
.p-dialog{
    max-height: 95%;
}
.p-fluid{
    label{
        margin-bottom: 5px;
    }
}
.images-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .card{
        max-width: 48%;
        margin-bottom: 10px;
        margin-right: 10px;
        img{
            max-width: 90%;
        }
    }
    .card:last-child{
        margin-right: 0;
    }
}
.layout-wrapper{
    overflow: hidden;
}
.table-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.logo-image {
    width: 10rem !important;
    height: 6rem !important;
}
.darked-card{
    background-color: #EEE;
    margin-top: 20px;
    .p-flex-group{
        margin-bottom: 15px;
    }
}
.edit-event-cards{
    padding: 15px;
    .p-flex-group, .p-fluid{
        margin-bottom: 10px;
    }
    .p-flex-group .group-block{
        input{
            width: 100%;
        }
    }
}
.mini-buttons{
    margin-top: 20px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    .group-block{
        align-items: center;
    }
}
.info-ent{
    textarea{
        margin-top: 10px;
        min-height: 120px;
    }
}
.footer-more-buttons{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 30px;
    .footer-button-div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.footer-to-start{
    align-items: flex-start !important;
}
.p-tabview-nav{
    align-items: center !important;
}
.checkbox-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    .checkbox-header{
        font-weight: 600;
        font-size: 14px;
    }
    .checkbox-group-inner{
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        margin-top: 10px;
        .group-block{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
.p-dialog-content{
    overflow-x: hidden;
}
button{
    justify-content: center;
}
.p-tabview .p-tabview-nav{
    justify-content: flex-start;
    padding-left: 60px;
}
.table-header-inner-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.responsive-dashboard{
    flex-direction: column-reverse;
    .card:nth-child(2){
        margin-bottom: 20px;
        .chart-header{
            color: #294f94 !important;
            text-align: center;
        }
    }
}
.calendar-flex{
    justify-content: flex-start !important;
    align-items: flex-start;
    flex-direction: column !important;
    .p-calendar{
        width: 100%;
    }
}
.image-card-main-container{
    display: flex;
    justify-content: center;
    .image-card-container{
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        padding: 5px;
        border: 1px solid #efefef;
        .image-card{
            margin: 0 auto;
            img{
                padding: 5px;
            }
        }
        .close-button {
            position: absolute;
            width: 20px;
            height: 20px;
        }
    }
}
.p-flex-group, .block-row, .flex-row, .dialog-product{
    .group-block, .w-33, .flex-column{
        .p-calendar{
            button{
                width: 10%;
            }
        }
        input{
            width: 100%;
        }
    }
}
.center{
    justify-content: center !important;
}
.flex-align{
    align-items: flex-end;
}
.footer-text{
    font-weight: 400;
}
.topbar-left{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .topbar-left-menu{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .topbar-left-user-block{
        display: flex;
        flex-direction: row;
        .p-dropdown{
            border: 0;
            .p-dropdown-trigger{
                display: none;
            }
        }
    }
}
    .main-banner{
        background:url(../../assets/images/title8.jpg);
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        left: -10%;
        width: 120%;
        top: -50px;
        .legal-header{
            position: relative;
            z-index: 105;
            color: #fff;
            text-align: center;
            top: 100px;
            font-size: 42px;
            font-weight: 400;
        }
    }
    .darker{
        position: absolute;
        z-index: 99;
        background: rgba(0,0,0, 0.5);
        width: 100%;
        height: 100%;
    
    }
    .legal-content{
        position: relative;
        .info-content{
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            button{
                align-self: center;
                padding: 10px 0px 10px 0px;
                font-size: 18px;
            }
        }
    }
.layout-root-menuitem:last-child{
    .layout-menu{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        li{
            width: 48%;
        }
    }
}
.menu-flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    span{
        margin-bottom: 0 !important;
        color: rgba(255, 255, 255, 0.8);
        text-transform: capitalize;
        font-weight: 500;
    }
}
.field{
    padding: 5px;
}
.jsfe{
    justify-content: center;
}
a{
    i::before{
        color:#495057;
    }
}
.login-panel {
    width: 100%;
    z-index: 1 !important;
}
.linear-bg{
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #eeeeee;
    background-size: cover;
    background-blend-mode: overlay;   
}
.login-form{
    h2{
        color: lighten($primaryBackground, 25%) !important;
    }
    .login-button-group{
        .underlined, div{
            color: lighten($primaryBackground, 10%) !important;
        }
        .p-button-outlined{
            .p-button-label{
                color: lighten($primaryBackground, 10%) !important;
            }
        }
    }
}

