$gutter: 1rem;

@import 'node_modules/primeflex/primeflex.scss';
@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';
@import './assets/styles/app/training/training.scss';
@import './pages/shop/shop-product.scss';
@import './pages/map/leaflet-edit-route.scss';
@import './pages/map/components/detailed-route/detailed-route.component.scss';
@import './components/spooorts/spooorts-tables-grid.scss';
@import './darkmode/darkmode.scss';