// @import './public/assets/theme/blue/theme-light.scss';
.darkMode{
    @import '../../public/assets/theme/blue/theme-dark.scss';
    // switch
    .p-input-switch{
        &.p-inputswitch-checked{
            background-color: $primaryColor;
        }
    }
    .p-button{
        border-color: $primaryColor !important;
        background-color: $primaryColor !important;
    }
    .btn-outline{
        background-color: transparent !important;
        color: $white !important;
    }
    .p-button-outlined{
        border: 1px solid $primaryColor !important;
        background-color: transparent !important;
    }

    // layout-content-wrapper
    .layout-content-wrapper, .container-block{
        background-color: $primaryBackground;
    }
    // topbar
    .layout-topbar{
        background-color: $primaryBackground;
        border-color: lighten($primaryBackground, 20%) !important;
    }
    .topbar-left{
        border-color:lighten($primaryBackground, 20%) !important;
        .topbar-left-menu{
            button{
                background-color: lighten($primaryBackground, 7%);
            }
            .pi::before{
                color:$white;
            }
        }
    }
    .layout-breadcrumb span{
        color: $light
    }
    .topbar-left-user-block{
        button{
            background-color: transparent !important;
            .pi::before{
                color: $primaryColor;
            }
        }
    }
    .p-dropdown{
        background-color: $dark !important;
        border-color: $dark;
    }
    .container-block .block-row .group-block{
        .p-dropdown{
            background-color: $dark !important;
            border-color: $dark;
        } 
    }
    .p-dropdown-trigger{
        max-width: 15%;
        .p-dropdown-trigger-icon::before{
            color: $white;
        }
    }
    .p-dropdown-label, .p-dropdown-trigger{
        background-color: $dark !important;
        color:$light !important;
    }
    .p-dropdown-items{
        background-color: lighten($primaryBackground, 7%);
        color: $lightgray;
    }
    .p-dropdown-item{
        color: $lightgray !important;
    }
    .p-dropdown-item:hover{
        background-color: $dark !important;
        color: $lightgray !important;
    }
    .p-sortable-column:hover{
        background-color: $dark !important;
    }

    // card
    .card, .flex-column{
        background-color: lighten($primaryBackground, 15%);
        border-color: lighten($primaryBackground, 20%);
    }

    // datatable
    .table-header-inner-container .pi-plus::before{
        color: $primaryColor;
    }
    .p-datatable{
        .p-datatable-header{
            background-color: lighten($primaryBackground, 15%);
            border-color: lighten($primaryBackground, 15%);
            .pi-plus::before{
                color: $primaryColor;
            }
        }
        .p-datatable-tbody > tr{
            background-color:$primaryBackground;
        }
        .p-datatable-thead > tr > th{
            background-color:lighten($primaryBackground, 10%);
        }
        .p-column-title:hover{
            color: $dark !important;
            .p-column-header-content .p-column-title:hover{
                color: $lightgray !important;
            }
        }
        .p-selectable-row:hover{
            background-color: $dark !important;
        }
        th, td, tr{
            border-color: lighten($primaryBackground, 20%) !important; 
        }
        .p-column-header-content span, .p-column-header-content span::before, tr td{
            color: $white;   
        }
    }
    .p-paginator{
        background-color: lighten($primaryBackground, 10%);
        border-color: lighten($primaryBackground, 20%) !important; 
        .pi::before{
            color: $light;
        }
    }
    // input
    .p-inputtext{
        background-color: $dark;
        color:$white;
        border-color: $dark;
    }
    input::placeholder{
        color: $white;
    }
    .p-editor-content, .p-editor-toolbar, .ql-editor{
        background-color: $dark !important;
        border-color: lighten($primaryBackground, 15%)  !important;
        color: $white;
    }
    .ql-picker-label, .ql-stroke, .ql-even, .ql-fill, .ql-color-label {
        color: $white !important;
        stroke: $white !important;
    }

    // dialog
    .p-dialog-content, .p-dialog-header, .p-dialog-footer{
        background-color: lighten($primaryBackground, 15%) !important;
    }
    .p-dialog-footer{
        .buttons{
            .p-button{
                border-color: $primaryColor !important;
            }
            .p-button:nth-child(1){
                background-color: $primaryColor !important;
            }
            .p-button:nth-child(2){
                background-color: transparent !important;
                color: $white !important;
            }
        }
    }
    // tabview
    .p-tabview-nav, .p-tabview-nav-link, .p-tabview-panels{
        background-color: lighten($primaryBackground, 15%)  !important;
    }
    .p-tabview-nav{
        border-color: lighten($primaryBackground, 25%)  !important;
    }

    // icon
    .p-dialog-header-close-icon::before{
        color: $white;
    }
    .pi-search::before, .pi-upload::before, .pi-shopping-cart::before, 
    .pi-shopping-bag::before, .pi-calendar-plus::before, .pi-user-plus::before,
    .pi-euro::before, .pi-ticket::before, .pi-filter::before, .pi-times::before{
        color: $primaryColor;
    }
    .smallimg span img{
        background-color: $white;
    }
    .buttons-row-container{
        .pi-plus::before{
            color: $primaryColor !important; 
        }
    }
    .table-header-container{
        .pi::before{
            color: $primaryColor !important;
        }
    }
    .group-block{
        .pi-plus::before{
            color: $primaryColor !important;
        }
    }
    .mini-buttons{
        .pi-plus::before{
            color: $white !important;
        }
    }



    // text
    h4, label, p, span, h2{
        color: $white !important;
    }
    .colored{
        color: $primaryColor;
    }
    .card:nth-child(2) .chart-header{
        color: $primaryColor !important;
    }
    hr{
        border-color:lighten($primaryBackground, 30%) !important;
    }
    .p-dialog-title{
        color: $light;
    }

    .logoPdf, .logoXml, .logoCsv, .inverted-img{
        filter: invert(1);
    }
    .image-card-container{
        background-color: $white;
    }

    // datepicker
    .p-datepicker-group, .p-datepicker , .p-datepicker-header{
        background-color: $primaryBackground !important;
    }

    // calendar
    .fc-toolbar-title{
        color: $lightgray;
    }
    .fc.fc-theme-standard .fc-view-harness th, .fc.fc-theme-standard .fc-view-harness td{
        background-color: $primaryBackground !important;
        border-color: lighten($primaryBackground, 15%) !important;
    }
    .fc-daygrid-more-link{
        color: $light;
    }

    button:hover{
        background-color: $dark;
    }

    .flex button .pi-plus::before{
        color: $white;
    }

    .linear-bg{
        position: absolute;
        width: 100%;
        height: 100vh;
        background-color: lighten($primaryBackground, 10%);
        background-size: cover;
        background-blend-mode: overlay;
    }
    .login-form{
        .login-button-group{
            div, .underlined{
                color: $light !important;
            }
            .p-button-outlined{
                .p-button-label{
                    color: $light !important;
                }
            }

        }
    }









    .mobile-footer, .desktop-footer{
        background-color: lighten($primaryBackground, 15%);
        border-color: lighten($primaryBackground, 25%);;
        .pi::before{
            color: $white;
        }
    }
}